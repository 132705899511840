
  .product_wp {
    overflow: hidden;
    margin-top: 40px;
    margin: auto 40px;
    z-index: 10;
    @media (max-width:786px) {
        margin: auto 30px;

    }

  }


