@import url("https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap");

.font-serif {
  font-family: "Lora", serif;
}


.fade-in {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.fade-out {
  opacity: 0;
  transform: translateY(20px); /* Adjust this value based on your design */
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}
.offeringbox {
  
  border: 1px solid rgb(95, 92, 92);
  z-index: 100;
  position: absolute;
  left: 17vw;
  margin-top:5vw; 
}

.offeringimage{
  gap: 20%;
  @media (max-width:360px) {
    gap: 10%;

  }
}

.offeringimage2{
  gap: 120px;
  @media (min-width:450px) {
    gap: 10%;

  }
}

.offeringbox1{
  height: 430px;
  width: 600px;
  border: 1px solid rgb(49, 37, 37);
  z-index: 100;
  position: absolute;
  left: 10vw;
  margin-top:5vw; 
}

