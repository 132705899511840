@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Arial, Helvetica, sans-serif;
  src: url('/public/BebasNeue-Regular.ttf');
}

body {
  margin: 0;
  padding: 0;

  font-family: 'Lora', serif;

}
.navbar {
  /* border-bottom: 2px solid black; */
  z-index: 10;
  position: relative;
  box-shadow: 5px 5px rgba(138, 62, 62, 0.664);
}
.banner {
  z-index: -1;
}

.contact-page {
  background-image: url("../public/contact01.jpeg");
  background-repeat: no-repeat;
  /* position: absolute; */
}

.page_font{
  font-family: Arial, Helvetica, sans-serif ;
}

.heading_main{
  font-family: 'Lora', serif;
  font-weight: bold;
  color: black;
  text-align: center;
  text-transform: uppercase;
}

.subheading_main{
  font-family: 'Lora', serif;
  font-weight: bold;
  color: #063970
}